<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="orders-status-start  box-flex">
      <div class="container review-main text-capitalize position-relative  box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card | d-flex flex-column ">
              <div class="center-card-box | p-3 top-sticky border-bottom">
                <div class="resturant-details">
                  <h4 class="text-start">{{ $t('Orders') }}</h4>
                </div>
              </div>
              <div class="center-card-box | p-3 flex-grow-1 position-relative">
                <div class="orders-tab-main">
                  <b-tabs content-class="my-3" lazy>
                    <b-tab :title="$t('Ongoing')" active @click="step = 0">
                      <on-going :selectTab="step"/>
                    </b-tab>
                    <b-tab :title="$t('Past')" @click="step = 1">
                      <past :selectTab="step" />
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
    
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import OnGoing from '../components/orders/OnGoing.vue';
import Past from '../components/orders/Past.vue';
import Footer from '../components/Footer.vue';
export default {
  components: {
    NavBar,
    OnGoing,
    Past,
    Footer
  },
  data() {    
    return {
      step: '0',
    }
  },
  mounted() {
    setTimeout(() => {      
      if(this.$auth && !this.$auth.user){
        this.$router.push({ name: "sign-in" });
      }
    }, 500);
  }
};
</script>