<template>
  <div id="past" class="tab-pane tab-box">
    <div class="orders-lst skelton" v-if="is_skel_loader && pastorders.length == 0">
      <a href="javascript:void(0);">
        <div class="order-item d-flex align-items-start">
          <div class="img-cls resimg-squre | d-inline-flex align-items-center justify-content-center">
            <skeleton-loader-vue width="85px" height="85px" style="border-radius: 5px;" />
          </div>
          <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
            <div class="orders-status-main delivery-link">
              <h6 class="mb-1"><skeleton-loader-vue type="string" width="130px" height="20px" /></h6>
              <skeleton-loader-vue type="string" width="50px" height="20px" />
              <p class="mt-2"><skeleton-loader-vue type="string" width="100px" height="20px" /></p>
            </div>
            <div class="order-status">
              <p class="order-status-btn"><skeleton-loader-vue type="circle" width="100px" height="20px"
                  style="border-radius: 20px !important;" /></p>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div v-else>
      <div class="orders-lst new-orders-lst" v-if="pastorders.length > 0">
        <div class="pastorderloop pb-2 mb-2" v-for="(past, index) in   pastorders  " :key="index">
          <router-link
            :to="{ name: past.order_status == 'Cancelled' ? 'order-details' : 'order-status', params: { order_id: base64_encode(past.id) } }">
            <div class="order-item d-flex align-items-start ">
              <div class="img-cls resimg-squre"><img :src="past.icon_image" /></div>
              <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                <div class="orders-status-main delivery-link">
                  <h6 class="mb-1">{{ past.restaurant_name }}</h6>
                  <span v-html="$auth.setting.currency" class="currency"></span> <span>{{
                    parseFloat(past.total_amount).toFixed(2) }}</span>
                  <p class="mt-2">{{ moment.utc(past.created_at).fromNow() }}</p>
                </div>
              </div>
            </div>
          </router-link>
          <div class="new-btn-order">
            <div class="order-status" v-if="past.delivery_pickup_types == 'Pickup' && past.order_status == 'Delivered'">
              <p class=" delivered order-status-btn">{{ $t('Collected') }}</p>
            </div>

            <div class="order-status" v-else-if="past.order_status == 'Delivered' || past.order_status == 'Pickup'">
              <p class=" delivered order-status-btn">{{ $t('Delivered') }}</p>
            </div>

            <div class="order-status" v-else>
              <p class="canceled order-status-btn">{{ $t('Cancelled') }}</p>
            </div>
            <div class="reorder-div">
              <p class="order-status-btn reorder" @click="reorderClick(past)">{{ $t('Reorder') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="order-offers | position-absolute top-50 start-50 translate-middle">
        <p class="text-center">{{ $t('No Past Order Found') }}</p>
      </div>
    </div>
    <!--Alert Modal start-->
    <div id="touchFooter"></div>
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <!--Alert Modal End-->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import VueSkeletonLoader from 'skeleton-loader-vue';
import AlertModal from '../../components/AlertModal.vue';


export default {
  props: ["selectTab"],
  components: { 'skeleton-loader-vue': VueSkeletonLoader, AlertModal },
  data() {
    return {
      pastorders: [],
      moment: moment,
      totalrecord: 20,
      isStopCallingApi: false,
      skip: 0,
      is_skel_loader: true,
      isAlert: false,
      AlertData: {},
      tempOrder: {},
      isScrollStart: true
    };
  },

  mounted() {
    if (this.selectTab == '1') {
      if (this.$route.name == 'orders') {
        setTimeout(() => {
          const containerEl = document.getElementById('touchFooter');
          const io = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) {
              if (!this.isStopCallingApi) {
                this.isScrollStart = true;
                this.$store.commit("order/pastOrderData", []);
                this.getPastOrders();
              }
            }
          });
          io.observe(containerEl);
        }, 1000);
      }
    }
  },
  computed: {
    ...mapGetters("product", ["cart",]),
    ...mapGetters("order", ["pastOrdersData"]),
  },
  methods: {
    ...mapActions("order", ["getPastOrder", "reorderDataDetails"]),
    ...mapMutations("product", ["clearCartItems"]),
    ...mapMutations("order", ["pastOrderData"]),
    ...mapMutations("restaurant", ["getRestaurantBySlug"]),

    getPastOrders() {
      if (this.$route.name == 'orders' && this.selectTab == '1') {
        this.is_skel_loader = true;
        if (this.pastOrdersData && this.pastOrdersData.length > 0) {
          this.is_skel_loader = false;
          this.pastorders = this.pastOrdersData;
          this.skip = this.pastOrdersData.length;
        } else {
          this.getPastOrder({
            vendor_id: this.$auth.getVendorId(),
            is_langauge: this.$store.state.lang.locale,
            totalrecord: this.totalrecord,
            skip: this.skip
          }).then((data) => {
            if (data.code == 200) {
              this.is_skel_loader = false;
              this.skip = this.skip + 20;
              if (!this.pastorders.length) {
                data.Result.forEach((resOrder) => {
                  this.pastorders.push(resOrder);
                });
              } else {
                data.Result.forEach((resOrder) => {
                  let checkRest = this.pastorders.find((elm) => elm.id == resOrder.id);
                  if (!checkRest) {
                    this.pastorders.push(resOrder);
                  }
                });
              }
              this.$store.commit("order/pastOrderData", this.pastorders);
            } else if (data.Result.length == 0) {
              this.is_skel_loader = false;
              this.isStopCallingApi = true;
              this.$store.commit("order/pastOrderData", this.pastorders);
            }
          });
        }
      }
    },

    onModalCloseFun(value) {
      this.isAlert = false;
      if (value == 'yes') {
        this.$store.commit("product/clearCartItems");
        this.createCart(this.tempOrder);
      }
    },

    reorderClick(order) {
      if (this.cart.length == 0) {
        this.createCart(order);
      } else {
        var checkCartRestaurant = this.cart.find((ele) => (ele.restaurant_id == order.restaurant_id));
        if (checkCartRestaurant) {
          this.createCart(order);
        } else {
          this.AlertData = {
            displayMessage: this.$t('Your cart contain items from another store. Do you wish to clear cart and start a new order here?'),
            isSetClass: 'prdct-modal-cls'
          };
          this.isAlert = true;
          this.tempOrder = order;
          window.$("#item-popup").modal("hide");
          window.$(this.$refs.AlertModal).modal();
        }
      }
    },

    createCart(order) {
      this.reorderDataDetails({
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
        order_id: order.id,
      }).then((data) => {
        if (data.code == 200) {
          let restaurant = data.restaurant_details;
          this.$store.commit("restaurant/getRestaurantBySlug", restaurant);
          this.$store.commit("product/clearCartItems");
          if (data.Result.length > 0) {
            data.Result.forEach((res) => {
              let roption = {};
              let selected_type = [];

              let arraySelectCustoizeID = [];
              let arraySelectCustoizePrice = [];

              if (res.customize_type.length > 0) {
                let option = [];
                res.customize_type.forEach((elm) => {
                  elm.option.forEach((elmoption) => {
                    selected_type.push(elmoption.selection_name);
                    arraySelectCustoizeID.push(elmoption.selection_id);
                    arraySelectCustoizePrice.push(elmoption.selection_price);

                    if (elmoption.options.length > 0) {
                      elmoption.options.forEach((elm) => {
                        selected_type.push(elm.selection_name);
                        arraySelectCustoizeID.push(elm.selection_id);
                        arraySelectCustoizePrice.push(elm.selection_price);
                      });
                    }
                  });
                });
              }
              var customizeTotal = arraySelectCustoizePrice.reduce((a, b) => a + b, 0);
              let savemrp = 0;
              if (res.price < res.mrp) {
                savemrp = res.mrp - res.price;
              }
              let itemTax = 0;
              if (res.item_tax != '0') {
                itemTax = (res.price - (res.price / (1 + (res.item_tax / 100))));
              }
              var AddToCartItemArray = {
                restaurant_id: res.restaurant_id,
                item_id: res.item_id,
                item_name: res.item_name,
                price: customizeTotal + res.price,//res.amount/res.quantity,
                quantity: res.quantity,
                itemTotalQuantity: res.item_quantitys,
                selected_type: selected_type,
                selectCustoizeID: arraySelectCustoizeID,
                selectCustoizePrice: arraySelectCustoizePrice,
                item_type: res.item_type,
                item_price: res.price,
                image: res.image,
                saveprice: savemrp,
                item_tax: itemTax,
                mrp: res.mrp,
                packaging_charge: res.item_packaging_charge
              };
              //this.$store.dispatch("product/addProductToCart", AddToCartItemArray);
              this.$store.commit("product/creatReorderCart", AddToCartItemArray);
              this.$router.push({ name: "vendor", params: { restaurant_name: restaurant.slug } }).catch(() => { });
            });
          }
        }
        if (data.inactive_item > 0) {
          alert(this.$t("Few items are not available"));
        }
      });
    },

  },
};
</script>